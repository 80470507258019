import React from 'react';
import { FaGithub, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-background text-textSecondary py-8">
      <div className="container mx-auto px-6 text-center">
        <p>&copy; {new Date().getFullYear()} tradesol.io. All rights reserved.</p>
        <div className="mt-4 flex justify-center space-x-6">
          <a href="https://github.com/tradesol-io/tradesol.io" className="hover:text-primary">
            <FaGithub size={24} />
          </a>
          <a href="mailto:contact@tradesol.io" className="hover:text-primary">
            <FaEnvelope size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;