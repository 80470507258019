import React from 'react';

const faqs = [
  {
    question: 'Do I need an API key?',
    answer: 'No, you can start using the API immediately without any API keys.',
  },
  {
    question: 'Are there any usage limits?',
    answer: 'There are no usage limits. You can make as many requests as you need.',
  },
  {
    question: 'Do you keep logs of my requests?',
    answer: 'We respect your privacy and do not keep any logs.',
  },
  {
    question: 'How do I get started?',
    answer: 'Simply make a POST request to our endpoint with the required parameters.',
  },
  {
    question: 'Is there any support available?',
    answer: 'Yes, you can contact us at contact@tradesol.io for any inquiries.',
  },
  {
    question: 'Are there any fees?',
    answer: 'Yes, there are fees, but they are less than one percent per transaction.',
  },
  {
    question: 'Which DEXs is it compatible with?',
    answer: 'It is compatible with Raydium and Pumpfun.',
  },
  {
    question: 'source code available?',
    answer: 'the source code is fully available on https://github.com/tradesol-io/tradesol.io, and royalty-free!',
  },
];

const FAQ = () => {
  return (
    <section id="faq" className="py-20 bg-background text-textPrimary">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        <div className="space-y-8">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-card p-6 rounded-lg shadow">
              <h3 className="text-2xl font-semibold mb-2">{faq.question}</h3>
              <p className="text-textSecondary">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;