import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const snippets = {
  javascript: `const fetch = require('node-fetch');

const payload = {
  private_key: 'your_private_key',
  token_mint: 'token_address',
  gas_fee: 0.000001,
  amount_sol: 0.1,
};

fetch('https://api.tradesol.io/', {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
})
  .then((res) => res.json())
  .then((data) => console.log(data))
  .catch((err) => console.error(err));`,

  python: `import requests

payload = {
    'private_key': 'your_private_key',
    'token_mint': 'token_address',
    'gas_fee': 0.000001,
    'amount_sol': 0.1,
}

response = requests.post('https://api.tradesol.io/', json=payload)
print(response.json())`,

  go: `package main

import (
  "bytes"
  "encoding/json"
  "fmt"
  "net/http"
)

func main() {
  payload := map[string]interface{}{
    "private_key": "your_private_key",
    "token_mint":  "token_address",
    "gas_fee":     0.000001,
    "amount_sol":  0.1,
  }

  jsonData, _ := json.Marshal(payload)
  resp, err := http.Post("https://api.tradesol.io/", "application/json", bytes.NewBuffer(jsonData))
  if err != nil {
    fmt.Println(err)
    return
  }
  defer resp.Body.Close()

  var result map[string]interface{}
  json.NewDecoder(resp.Body).Decode(&result)
  fmt.Println(result)
}`,

  php: `<?php
$payload = [
    'private_key' => 'your_private_key',
    'token_mint' => 'token_address',
    'gas_fee' => 0.000001,
    'amount_sol' => 0.1,
];

$ch = curl_init('https://api.tradesol.io/');
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, ['Content-Type: application/json']);
curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($payload));

$response = curl_exec($ch);
curl_close($ch);

echo $response;
?>`,

  shell: `curl -X POST https://api.tradesol.io/ \\
  -H 'Content-Type: application/json' \\
  -d '{
    "private_key": "your_private_key",
    "token_mint": "token_address",
    "gas_fee": 0.000001,
    "amount_sol": 0.1
  }'`,

  ruby: `require 'net/http'
require 'json'

uri = URI('https://api.tradesol.io/')
payload = {
  private_key: 'your_private_key',
  token_mint: 'token_address',
  gas_fee: 0.000001,
  amount_sol: 0.1,
}

http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true
request = Net::HTTP::Post.new(uri.path, {'Content-Type' => 'application/json'})
request.body = payload.to_json

response = http.request(request)
puts response.body`,
};

const CodeSnippet = () => {
  const [activeTab, setActiveTab] = useState('javascript');

  return (
    <div className="bg-card p-8 rounded-lg shadow">
      <div className="flex flex-wrap gap-4 mb-6">
        {Object.keys(snippets).map((lang) => (
          <button
            key={lang}
            onClick={() => setActiveTab(lang)}
            className={`px-4 py-2 mt-2 rounded ${
              activeTab === lang ? 'bg-primary text-background' : 'bg-background text-textSecondary hover:text-primary'
            }`}
          >
            {lang.toUpperCase()}
          </button>
        ))}
      </div>
      <SyntaxHighlighter
        language={activeTab}
        style={dracula}
        customStyle={{ backgroundColor: 'transparent' }}
        showLineNumbers
      >
        {snippets[activeTab]}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeSnippet;