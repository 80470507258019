import React, { useState } from 'react';
const Header = () => {
  const [isOpen] = useState(false);

  return (
    <header className="bg-background text-textPrimary shadow">
      <div className="container mx-auto px-6 py-4 flex items-center justify-between">
        <div className="text-3xl font-bold">
          <a href="#" className="hover:text-primary">
            tradesol.io
          </a>
        </div>
        {/* Menu button for mobile */}
        
        {/* Navigation links */}
        <nav
          className={`${
            isOpen ? 'block' : 'hidden'
          } absolute top-full left-0 w-full bg-background md:bg-transparent md:static md:flex items-center justify-end md:space-x-6`}
        >
          <a
            href="#features"
            className="block px-6 py-2 md:px-0 md:py-0 text-textSecondary hover:text-primary"
          >
            Features
          </a>
          <a
            href="#documentation"
            className="block px-6 py-2 md:px-0 md:py-0 text-textSecondary hover:text-primary"
          >
            Documentation
          </a>
          <a
            href="#contact"
            className="block px-6 py-2 md:px-0 md:py-0 text-textSecondary hover:text-primary"
          >
            Contact
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;