import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import CodeSnippet from './components/CodeSnippet';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

function App() {
  return (
    <div className="font-sans bg-background text-textPrimary">
      <Header />
      <main>
        <Hero />
        <Features />
        <section id="documentation" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12">Get Started</h2>
            <p className="text-center mb-8 text-textSecondary">
              Make a single POST request to <code className="bg-card px-2 py-1 rounded">https://api.tradesol.io/</code> with the following JSON payload:
            </p>
            <CodeSnippet />
          </div>
        </section>
        <FAQ />
      </main>
      <Footer />
    </div>
  );
}

export default App;