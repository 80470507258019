import React from 'react';
import heroImage from '../assets/solana.png'; // Replace with an appropriate image

const Hero = () => {
  return (
    <section className="bg-background text-textPrimary py-20">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
        <div className="flex-1">
          <h1 className="text-5xl md:text-6xl font-bold leading-tight">
            Trade SOL to Any Token with a Single API Request
          </h1>
          <p className="mt-6 text-xl text-textSecondary">
            No API keys. No logs. No limits.
          </p>
          <div className="mt-8 flex justify-center">
            <a
              href="#documentation"
              className="inline-block bg-primary text-background px-8 py-4 rounded-full font-semibold shadow hover:shadow-lg transition duration-300"
            >
              Get Started
            </a>
          </div>
        </div>
        <div className="flex-1 mt-12 md:mt-0">
          <img src={heroImage} alt="Trading Illustration" className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default Hero;