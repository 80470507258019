import React from 'react';
import { FaLock, FaInfinity, FaFeatherAlt, FaCode } from 'react-icons/fa';

const features = [
  {
    icon: <FaCode size={40} className="text-primary" />,
    title: 'Simple Integration',
    description: 'No need for additional libraries. Start trading immediately with a single API call.',
  },
  {
    icon: <FaLock size={40} className="text-primary" />,
    title: 'Privacy Focused',
    description: 'We keep no logs of your requests. Your transactions remain private and secure.',
  },
  {
    icon: <FaInfinity size={40} className="text-primary" />,
    title: 'Unlimited Access',
    description: 'No usage limits or restrictions. Trade as much as you need.',
  },
  {
    icon: <FaFeatherAlt size={40} className="text-primary" />,
    title: 'Lightweight',
    description: 'Minimal overhead and super fast. Enhance your projects without the bloat.',
  },
];

const Features = () => {
  return (
    <section id="features" className="py-20 bg-background text-textPrimary">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {features.map((feature, index) => (
            <div key={index} className="bg-card p-8 rounded-lg shadow hover:shadow-lg transition duration-300">
              <div className="flex items-center justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-textSecondary">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;